#recommendFoodList {
  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      width: 28px !important;
      height: 28px !important;
      border-radius: 50%;
      border: unset;
      background: rgba(16, 24, 40, 0.4);
      &:after {
        font-size: 5px;
        color: rgba(255, 255, 255, 1);
      }
    }
    .swiper-pagination {
      display: none;
    }
  }
}
