#languageDropdown {
  .p-dropdown .p-dropdown-label {
    padding: unset;
  }
}
.mobile-header .some-mobile-menu-item {
  display: none;
}

/* Example: hide the search icon on mobile */
.mobile-header .search-header-icon {
  display: none;
}

/* Example: show the mobile menu icon on mobile */
.mobile-header .menu-header-icon {
  display: block;
}
@media (min-width: 769px) {
  .flex .menu-header-icon {
    display: none;
  }
}

@media (max-width: 678px) {
  .mobile-header {
    display: none;
  }
}

.menu {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.7s ease-in-out;

  &.open {
    opacity: 1;
    transform: translateX(0);
  }
}
