#bannerView {
  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: unset;
      background: rgba(16, 24, 40, 0.4);
      &:after {
        font-size: 10px;
        color: rgba(255, 255, 255, 1);
      }
    }
    .swiper-pagination {
      display: flex;
      justify-content: center;
      bottom: 24px;
      .swiper-pagination-bullet {
        height: 4px;
        width: 16px;
      }
    }
  }
}

#searchView {
}

#listFoodView,
#book-again-restaurant,
#foodToday {
  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      width: 28px !important;
      height: 28px !important;
      border-radius: 50%;
      border: unset;
      background: rgba(16, 24, 40, 0.4);
      &:after {
        font-size: 5px;
        color: rgba(255, 255, 255, 1);
      }
    }
    .swiper-pagination {
      display: none;
    }
  }
  .swiper-button-disabled {
    @apply opacity-50 pointer-events-auto; 
  }
}
#listFoodView {
  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      top: 54px;
    }
    .swiper-pagination {
      display: none;
    }
  }
  
}

#popularLocation {
  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      width: 36px !important;
      height: 36px !important;
      border-radius: 50%;
      border: unset;
      background: rgba(16, 24, 40, 0.4);
      &:after {
        font-size: 5px;
        color: rgba(255, 255, 255, 1);
      }
    }
    .swiper-pagination {
      display: none;
    }
  }
  .swiper-button-disabled {
    @apply opacity-50 pointer-events-auto; 
  }
}
.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
  color: #0166ff;
}
/*  .p-dropdown-panel {
   position: absolute;
    top: 538px !important;
  } */

.p-tooltip .p-tooltip-text {
  @apply bg-gray-50 text-14 text-gray-500;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.04);
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  @apply border-r-gray-50;
}
.select-city{
  .p-dropdown .p-dropdown-label.p-placeholder{
   @apply pt-10px
  }
}