#video_full_screen{
	#cardFormModal .p-dialog-header{
		display: none;
	}
	.p-dialog-header-icons{
		position: absolute;
	}
}
#white-play-icon{
	color: white;
	border: 1px solid #FF9966;
	height: 24px;
	border-radius: 100px;
	width: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	background:#001100;
}