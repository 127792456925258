#custom-swiper {
	.swiper {
		.swiper-button-prev,
		.swiper-button-next {
			width: 28px !important;
			height: 28px !important;
			border-radius: 28%;

			&:after {
				font-size: 10px !important;
			}
		}
	}
}
#custom-button{
	.p-button{
	.p-button-label{
		font-weight: 600 !important;
	}
}
	.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button, .p-fileupload-choose.p-button-info {
		padding: 12px !important;
		color:white;
		font-size: 14px !important;
		font-style: normal;
		font-weight: 600 !important;
		line-height: 20px;
	}
	 
}