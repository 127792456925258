#custom-top-body{
	.custom-style{
		@apply bg-orange-dark-50
	}
}
#custom-dialog{
	.p-dialog .p-dialog-header  {
		@apply border-b border-gray-200
		
	}
}