.p-progressbar {
  border-radius: 0.375rem;
  border-width: 0;
  height: 0.75rem;
  background: #f2f4f7;
}

.p-progressbar .p-progressbar-value {
  margin: 0;
  border-radius: 0.375rem;
  border-width: 0;
  background: var(
    --Linear-Orange,
    linear-gradient(264deg, #ff692e 0%, #ff4405 100%)
  );
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  @apply text-yellow-300;
}

.p-chip {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 1rem;
  border-width: 1px;
  border-style: solid;
  background-color: #ffffff;
  color: #495057;
  border-color: #475467;
}
.total-rating {
  background: var(
    --Linear-Orange,
    linear-gradient(264deg, #ff692e 0%, #ff4405 100%)
  );
  background-clip: text;
  color: transparent;
}

.selectedShadow {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
}
