#video_full_screen {
  border-radius: 12px;
  border: 1px solid white;
  .p-dialog-header {
    padding: 0;
  }

  .p-dialog-header-icons {
    position: absolute;
    top: -8px;
    z-index: 1;
    right: -8px;
    color: black;
    background: white;
    border-radius: 100%;
    :focus {
      box-shadow: none;
    }
    z-index: 10;
  }
  .pi:before {
    color: white;
    text-shadow: 0 0 2px black;
  }

  #video_full_screen_content {
    border-radius: 12px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    @apply h-40px w-40px rounded-circle border bg-white;
    &:after {
      @apply text-16 font-semibold text-gray-800;
    }
  }
}
