.swiper-button-prev,
.swiper-button-next {
  @apply rounded-full border bg-white shadow;
  height: 52px !important;
  width: 52px !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  @apply font-black text-black;
  font-size: 14px !important;
}
.swiper-custom{
  .swiper-button-prev, .swiper-button-next {
    width: 28px !important;
    height: 28px !important;
    border-radius: 50%;
    border: unset;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #eaecf0;
    &:after {
      font-size: 5px;
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
      ;
    }
  }
}