#customSwitch {
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #1570ef;
  }
  .p-inputswitch {
    width: 36px;
    height: 20px;
  }
  .p-inputswitch .p-inputswitch-slider:before {
    width: 14px;
    height: 14px;
    margin-top: -7px;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1rem);
  }
}

#shopInformation {
  .p-dropdown-trigger {
    @apply flex;
  }
}

#activityTime {
  .p-icon {
    @apply text-gray-500
  }
  .p-button.p-component.p-datepicker-trigger.p-button-icon-only {
    @apply border-gray-300
  }

  .p-button.p-component.p-datepicker-trigger.p-button-icon-only:focus,
  .p-button.p-component.p-datepicker-trigger.p-button-icon-only:hover,
  .p-button.p-component.p-datepicker-trigger.p-button-icon-only:active {
    background-color: transparent;
    box-shadow: none;
    outline: none;
  }
}



