#mapModal {
  .p-dialog-content {
    padding: 0px 12px 12px 12px;
  }
  .p-dialog-header {
    padding: 12px 12px 0px 12px;
  }
}

@media (max-width: 768px) {
  #buyVoucherModal {
    .p-dialog-header {
      padding-left: 54px;
      padding-right: 54px;
    }
  }
}
