.input-number-custom {
  .p-inputnumber {
    background: white;
    padding: 4px;
    border-radius: 8px !important;
    width: 100%;
    .p-inputnumber-input {
      border: unset;
      border-radius: unset;
      flex: unset;
      padding: 4px 0px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      width: 100%;
      max-width: 110px;
      &:hover, &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        border-color: none;
      }
    }
    .p-inputnumber-button {
      padding: 6px;
      width: unset;
      border: unset;
      &:hover {
        background: inherit;
        color: inherit;
      }
    }
  }
}