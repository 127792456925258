.p-menu .p-menuitem-link .p-menuitem-icon {
  @apply text-gray-700;
}

.p-menu .p-menuitem-link .p-menuitem-text {
  @apply text-14 font-medium leading-5 text-gray-700;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background-color: transparent;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  height: 0.5rem;
  width: 0.5rem;
}

#share-to-fit-dialog_content {
  @apply rounded-3 p-0;
}
