#dialogFooter {
  button {
    margin: unset;
    width: unset;
  }
}

#languageModal {
  .p-dialog-content {
    overflow-y: unset;
  }
}
