// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
 
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);

// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
.custom-button-shadow {
  box-shadow: rgba(255, 165, 0, 0.5) 0px 0px 0px, rgba(255, 165, 0, 0.5) 0px 4px 8px, rgba(255, 165, 0, 0.5) 0px 0px 0px, rgba(255, 165, 0, 0.5) 0px 1px 1px, rgba(255, 165, 0, 0.5) 0px -1px 2px;
  color: white;
  background-color: orange;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  @apply mt-0;
}
.p-toast-message-content {
  @apply flex items-center;
}