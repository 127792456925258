.p-checkbox .p-checkbox-box.p-highlight {
  @apply border-blue-600 bg-white;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  @apply text-blue-600;
}

.p-checkbox:not(.p-checkbox-disable) .p-checkbox-box.p-focus {
  @apply border-blue-600 bg-white;
}

.p-checkbox:not(.p-checkbox-disable) .p-checkbox-box:hover {
  @apply border-blue-600 bg-white;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  @apply border-blue-600;
}

.p-radiobutton .p-radiobutton-box {
  @apply border;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  @apply h-1 w-1 bg-blue-700;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  @apply border-blue-light-700 bg-white hover:bg-white;
}

.p-slider .p-slider-handle {
  @apply border-blue-500;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  @apply border-blue-500 bg-blue-500;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  @apply border-blue-500 bg-white;
}

::-webkit-scrollbar {
  display: none;
}

.p-dropdown-panel .p-dropdown-header {
  @apply border-none bg-white p-0;
}

.custom-button-shadow {
  box-shadow: rgba(255, 165, 0, 0.5) 0px 0px 0px,
    rgba(255, 165, 0, 0.5) 0px 4px 8px, rgba(255, 165, 0, 0.5) 0px 0px 0px,
    rgba(255, 165, 0, 0.5) 0px 1px 1px, rgba(255, 165, 0, 0.5) 0px -1px 2px;
  color: white;
  background-color: orange;
}

#radius-dropdown .p-dropdown .p-dropdown-label.p-placeholder {
  @apply text-14;
}
