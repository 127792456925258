#policyModal {
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #1570ef;
  }
  .p-inputswitch {
    width: 36px;
    height: 20px;
  }
  .p-inputswitch .p-inputswitch-slider:before {
    width: 14px;
    height: 14px;
    margin-top: -7px;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1rem);
  }
  .p-dialog-content {
    padding: 0px;
  }
}
